import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

function IconButton({
  onClick, disabled, variant = 'outline-secondary', defaultClass='btn-xs mr-1', title, icon, text = '', showIcon = true, className, iconImg, placement = 'bottom', ...restProps
}) {
  return (
    <OverlayTrigger
      popperConfig={{
        modifiers: {
          preventOverflow: {
            enabled: false
          }
        }
      }}
      placement={placement}
      overlay={<Tooltip bsPrefix={title ? 'tooltip' : 'hide'}>{title}</Tooltip>}
    >
      <Button
        onClick={onClick}
        disabled={disabled}
        variant={variant}
        size="sm"
        className={`${defaultClass} ${className}`}
        {...restProps}
      >
        {
          // eslint-disable-next-line no-nested-ternary
          showIcon ? iconImg ? <img src={iconImg} alt="Tour Icon" /> : <FontAwesomeIcon icon={icon} /> : null
        }
        {
          !!text && <span className="ml-1">{text}</span>
        }
      </Button>
    </OverlayTrigger>
  );
}

export default IconButton;
