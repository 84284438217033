import React, { useState, useCallback, useEffect } from 'react';
import { store, withServices } from 'reaf';
import { useAsyncDebounce } from 'react-table';
import ProjectPopups from '../ProjectPopups/ProjectPopups';
import messages from '../../../../locales';
import Projects from './Projects';
import { ProjectOpened } from '../../../constants/eventTrackerMessage';

const useReactTable = ({
  apiClient,
  projectsService,
  fileName,
  routeName,
  router,
  queries,
  resourceEndPoint,
  csvDownloadEndPoint
}) => {
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);

  const changeRoute = useAsyncDebounce((routeName, options) => {
    router.setRoute(routeName, options);
  }, 200);

  const fetchData = React.useCallback(
    (params) => {
      changeRoute(routeName, {
        ...params
      });
    },
    [router]
  );

  const requestServer = (endPoint, options) => {
    if(options?.params?._search) {
      options.params._search['name'] = encodeURIComponent(options.params._search.name)
    }
    return apiClient.get(endPoint, options);
  }

  const refreshData = React.useCallback(() => {
    const endPoint = resourceEndPoint;
    const params = {
      ...queries
    };

    if (params._limit) {
      setLoading(true);
      requestServer(endPoint, { params }).then((result) => {
        setData(result.response.rows);
        setPageCount(Math.ceil(result.response.count / params._limit));
        setLoading(false);
      });
    }
  }, [queries]);

  React.useEffect(() => {
    refreshData();
  }, [refreshData]);

  const onDownloadCSV = React.useCallback(async () => {
    const endPoint = csvDownloadEndPoint;
    const params = {
      ...queries
    };

    setLoading(true);
    await projectsService.downloadProjectCSV(endPoint, { params }, fileName);
    setLoading(false);
  }, [queries]);

  return {
    data,
    setData,
    loading,
    setLoading,
    pageCount,
    setPageCount,
    fetchData,
    onDownloadCSV,
    refreshData
  };
};

function ProjectsView({
  queries,
  setHideTourButton,
  projectsService,
  router,
  apiClient,
  toastrService,
  eventTrackerService
}) {
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showAddProjectModal, setShowAddProjectModal] = useState(false);
  const [showClearTaggingConfirm, setShowClearTaggingConfirm] = useState(false);
  const [showEditProject, setShowEditProject] = useState(false);
  const [needSupport, setNeedSupport] = useState(false);
  const [project, setProject] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(false);

  const {
    data: projects,
    setData: setProjects,
    loading,
    pageCount,
    fetchData: fetchProjects,
    refreshData
  } = useReactTable({
    queries,
    apiClient,
    router,
    projectsService,
    routeName: 'projectsPage',
    resourceEndPoint: '/projects',
    fileName: 'Projects.csv'
  });

  function tileContainerClicked(e) {
    if (e.target === e.currentTarget) {
      setSelectedProject(null);
    }
  }

  function onPopupHide() {
    setNeedSupport(false);
    setShowDeleteConfirm(false);
    setShowClearTaggingConfirm(false);
    setShowEditProject(false);
    setShowAddProjectModal(false);
  }

  function onSelect(project) {
    setSelectedProject(project);
  }

  async function onOpen(project) {
    router.setRoute('projectPage', {
      projectUuid: project.uuid
    });
    eventTrackerService.track(ProjectOpened, {
      ...project
    });
  }

  function onEdit(project) {
    setProject(project);
    setShowEditProject(true);
  }

  function onNeedSupport(project) {
    setProject(project);
    setNeedSupport(true);
  }

  function onDelete(project) {
    setProject(project);
    setShowDeleteConfirm(true);
  }

  function onClearTagging(project) {
    setProject(project);
    setShowClearTaggingConfirm(true);
  }

  const onDeleteConfirm = useCallback(
    async (project) => {
      onPopupHide();
      setIsDeleteButtonDisabled(true);
      await projectsService.deleteProject(project);
      toastrService.success(messages.toastMessage.DELETE_PROJECT);

      const index = projects.findIndex((proj) => proj.uuid === project.uuid);
      let updatedProjectArr = [...projects];

      updatedProjectArr.splice(index, 1);
      setProjects(updatedProjectArr);
      setIsDeleteButtonDisabled(false)
    },
    [projects]
  );

  async function onClearTaggingConfirm(project) {
    onPopupHide();
    await projectsService.clearTagging(project).catch(console.log);
    toastrService.success(messages.toastMessage.CLEAR_PROJECT_TAG);
  }

  const updateOrPushProject = useCallback(
    async (updatedProject) => {
      const index = projects.findIndex(
        (project) => project.uuid === updatedProject.uuid
      );
      let updatedProjectArr = [...projects];

      if (index !== -1) {
        updatedProjectArr[index] = updatedProject;
        setProjects(updatedProjectArr);
      } 
      refreshData();
    },
    [projects, queries, refreshData]
  );

  return (
    <>
      <Projects
        setHideTourButton={setHideTourButton}
        projects={projects}
        setProjects={setProjects}
        loading={loading}
        pageCount={pageCount}
        fetchProjects={fetchProjects}
        tileContainerClicked={tileContainerClicked}
        selectedProject={selectedProject}
        onClearTagging={onClearTagging}
        onDelete={onDelete}
        onEdit={onEdit}
        onOpen={onOpen}
        onNeedSupport={onNeedSupport}
        onSelect={onSelect}
        queries={queries}
        onAdd={() => setShowAddProjectModal(true)}
        isDeleteButtonDisabled={isDeleteButtonDisabled}
      />

      <ProjectPopups
        project={project}
        showAddProjectModal={showAddProjectModal}
        needSupport={needSupport}
        onHide={(updatedProject) => {
          onPopupHide();
          updatedProject &&
            updatedProject.uuid &&
            updateOrPushProject(updatedProject);
        }}
        showEditProject={showEditProject}
        showDeleteConfirm={showDeleteConfirm}
        onDeleteConfirm={() => onDeleteConfirm(project)}
        showClearTaggingConfirm={showClearTaggingConfirm}
        onClearTaggingConfirm={() => onClearTaggingConfirm(project)}
      />
    </>
  );
}

export default withServices(
  'projectsService',
  'router',
  'toastrService',
  'apiClient',
  'eventTrackerService'
)(ProjectsView);
