import React from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import IconButton from '../../core/Button/IconButton';
import WithTooltip from '../../core/WithTooltip/WithTooltip';

const ReportTemplatesModal = ({ show, onHide, model, onDownloadReportTemplate, onDeleteReportTemplate }) => {
  const getTemplateReportName = (reportTemplate) => {
    const { s3Name, createdAt } = reportTemplate;
    const match = s3Name.match(/^(.*)__[^.]+\.(.+)$/);
    if (match) {
      const fileName = match[1];
      return fileName;
    }

    const isBasedOnUUID = (s3Name.match(/-/g) || []).length === 4;
    if (isBasedOnUUID) {
      return 'Property Report';
    }
    return s3Name;
  }
  const getTemplateReportNameWithFilePath = (model) => {
    return model.reportFilePath.split("/").pop();
  }

  if (!model || !model.hasReportTemplateFile) return null;
  return (
    <Modal show={show} onHide={onHide} size={'md'} centered>
      <Modal.Header closeButton>
        <Modal.Title>Report Templates</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!!model.reportFilePath && (
          <Row key="1" className="d-flex justify-content-between align-items-center mb-2">
          <Col lg="8" className="text-with-ellipsis">{getTemplateReportNameWithFilePath(model)}</Col>
          <Col lg="4" className="d-flex">
            <IconButton
              className="btn btn-default btn-sm mb-1 mr-2"
              title="Download Report Template"
              variant="outline-primary"
              onClick={() => onDownloadReportTemplate(model, null)}
              icon={faDownload}
              text="Download"
            />
            <IconButton
              className="btn btn-default btn-sm mb-1"
              title="Delete Report Template"
              variant="outline-danger"
              onClick={() => onDeleteReportTemplate(model)}
              icon={faTrash}
              text="Delete"
            />
          </Col>
        </Row>
        )}
        {model.reportTemplate.map((template, index) => (
          <Row key={index} className="d-flex justify-content-between align-items-center mb-2">
            <Col lg="8" className="text-with-ellipsis">
              <WithTooltip placement="bottom" tooltipText={getTemplateReportName(template)}>
                  <span>{getTemplateReportName(template)}</span>
              </WithTooltip>
            </Col>
            <Col lg="4">
              <IconButton
                className="btn btn-default btn-sm mb-1 mr-2"
                title="Download Report Template"
                variant="outline-primary"
                onClick={() => onDownloadReportTemplate(model, template)}
                icon={faDownload}
                text="Download"
              />
              <IconButton
                className="btn btn-default btn-sm mb-1"
                title="Delete Report Template"
                variant="outline-danger"
                onClick={() => onDeleteReportTemplate(model, template)}
                icon={faTrash}
                text="Delete"
              />
            </Col>
          </Row>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReportTemplatesModal;
