import React, { useState, useCallback } from 'react';
import { withServices } from 'reaf';
import {
  Row, Col, ListGroup, Button
} from 'react-bootstrap';
import { faInfo as infoIcon } from '@fortawesome/free-solid-svg-icons/faInfo';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import AssetTypeBadge from '../AssetTypeBadge/AssetTypeBadge';
import IconButton from '../../core/Button/IconButton';
import SwitchButton from '../../core/SwitchButton/SwitchButton';
import ReportTemplatesModal from '../ReportTemplatesModal/ReportTemplatesModal';

function ToggleButton({ isActive, title, onChange }) {
  const [isActiveToggle, setIsActiveToggle] = React.useState(isActive)

  const onToggle = React.useCallback(() => {
    setIsActiveToggle(!isActiveToggle);
    onChange(!isActiveToggle)
  }, [isActiveToggle, onChange])

  return (
    <div className="d-flex flex-column">
      <label className="text-sm">{title}</label>
      <div>
        <SwitchButton
          isActive={isActiveToggle}
          onChange={onToggle}
        />
      </div>
    </div>
  );
} 

function TemplateModelListItem({
  model, template, onDeleteTemplateModel, onEditTemplateModel, 
  onDownloadModel, companyUUID, templateUUID, onEditModel, onModelInformation,
  onToggleConsolidatedModel, templateModelService
}) {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);

  const onDeleteReportTemplate = useCallback( async (model, reportTemplate) => {
    try {
      await templateModelService.deleteReportTemplate(companyUUID, templateUUID, model, reportTemplate);
    } catch (error) {
      if (error.status === 400) {
        // Let this alert be here... It's intentional to show the error to the admin.
        // eslint-disable-next-line no-alert
        alert(error.message);
      }
    }
  }, [companyUUID, templateUUID])


  const onDownloadReportTemplate = useCallback(async (model, reportTemplate) => {
    try {
      await templateModelService.downloadReportTemplate(companyUUID, templateUUID, model, reportTemplate);
    } catch (error) {
      if (error.status === 400) {
        // Let this alert be here... Its intentional to show error to admin.
        // eslint-disable-next-line no-alert
        alert(error.message);
      }
    }
  }, [companyUUID, templateUUID]);

  const assetType = model.assetType &&
      (<AssetTypeBadge key={model.assetType} AssetType={model.assetType} />);

  return (
    <ListGroup.Item>
      <Row>
        <Col xs lg="2">
          {model.modelName}
          {assetType}
        </Col>
        <Col xs lg="1" style={{ display: 'none' }}>
          <Button
              variant="outline-primary"
              className="btn btn-default btn-sm"
              onClick={() => onEditTemplateModel(model)}
            >
              Edit
            </Button>
        </Col>
        <Col xs lg="3" pt={5}>
          <ToggleButton 
            className="text-sm"
            isActive={model.canDownloadConsolidatedModel} 
            title="Allow Download consolidated model"
            onChange={(isActive) => onToggleConsolidatedModel(model, isActive)}
          />
        </Col>
        {<Col xs lg="4" className="d-flex align-items-center">
        {!!(!template.isGlobal) && (
          <>
            {!!model.modelFilePath && <IconButton
              className="btn btn-default btn-sm"
              variant="outline-primary"
              onClick={() => onDownloadModel(model)}
              title="Download Model"
              text="Model Template"
              icon={faDownload}
            />}
            {model.hasReportTemplateFile && <IconButton
              className="btn btn-default btn-md"
              variant="outline-primary"
              onClick={toggleModal}
              title="Manage Report Templates"
              text="Manage Reports"
            />}
          </>)}
        </Col>}
        <Col xs lg="3 d-flex align-items-center">
          {!template.isGlobal && (
            <>
              <IconButton
                className="btn btn-default btn-sm"
                title="Delete Report Template"
                variant="outline-danger"
                onClick={() => onDeleteTemplateModel(model)}
                icon={faTrash}
                text="Delete"
              />
              <IconButton
                className="btn btn-default btn-sm"
                title="Update Report Template"
                variant="outline-primary"
                onClick={() => onEditModel(model)}
                icon={faPencilAlt}
                text="Update"
              />
              <IconButton
                className="btn btn-default btn-sm"
                title="Model Information"
                variant="outline-primary"
                onClick={() => onModelInformation(model)}
                icon={infoIcon}
              />
            </>
          )}
        </Col>
      </Row>
      <ReportTemplatesModal
        show={showModal}
        onHide={toggleModal}
        model={model}
        onDownloadReportTemplate={onDownloadReportTemplate}
        onDeleteReportTemplate={onDeleteReportTemplate}
      />
    </ListGroup.Item>
  );
}

export default (withServices('templateModelService')(TemplateModelListItem));
