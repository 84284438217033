import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { withServices } from 'reaf';
import { useDropzone } from 'react-dropzone';
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap';
import DocumentPopups from '../DocumentPopups/DocumentPopups';
import { ExtractionJobStatus, MAX_ALLOWED_EXTRACTION } from '../../../constants';
import DocumentViewer from '../DocumentViewer/DocumentViewer';
import messages from '../../../../locales';
import style from './DocumentsView.module.scss';
import './DocumentsView.scss';
import uploadTextBackground from '../../../assets/images/upload-text.png';
import DocumentsGridView from './DocumentsGridView';
import DocumentsListView from './DocumentsListView';
import noDocumentsBackground from '../../../assets/images/no-documents.svg';
import { getIsDocumentReExtractRequired } from '../../../utils/utils';
import DocumentTaggingForm from '../DocumentTaggingForm/DocumentTaggingForm';
import ContactSalesForm from '../ContactSalesForm/ContactSalesForm';
import { currentProjectIsReportEnabledSelector, eventCountsSelector } from '../../../store/selectors';
import { appConfig } from '../../../config';
import { setCurrentActiveViewerSheet } from '../../../store/currentProject';
import {
  DocumentAddedByDrop,
  DocumentOpened
} from '../../../constants/eventTrackerMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';



const ModelMessage = () => {
  const [show, setShow] = useState(true);
  if (!show) {
    return null
  }
  return (
    <Alert variant="info" className='infoBanner w-100 m-0 rounded-0' onClose={() => setShow(false)} dismissible>
      <p className='m-0'>
        <span className='mr-1'><FontAwesomeIcon color='#1c456d' size={'lg'} icon={faInfoCircle} />
        </span> Note: Only the selected files will be used to generate the model
      </p>
    </Alert>
  )
}

function DocumentsView({
  project,
  documents,
  documentsService,
  projectsService,
  projectReportService,
  eventTrackerService,
  isListView = true,
  router,
  store,
  onDocumentSelect,
  toastrService,
  goToAddTaggingStep,
  showTaggingForm,
  setShowTaggingForm,
  selectedDocument,
  setSelectedDocument,
  onAddTagging,
  eventCounts,
  isReportEnabled,
  selectedDocumentsForModel = [],
  setSelectedDocumentsForModel,
  succesfulTaggedDocuments
}) {
  const [showRenameForm, setShowRenameForm] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showClearTaggingConfirm, setShowClearTaggingConfirm] = useState(false);
  const [showExtractionPendingPopup, setshowExtractionPendingPopup] =
    useState(false);
  const [document, setDocument] = useState(null);
  const [needSupport, setNeedSupport] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [sheetNames, setSheetNames] = useState([]);
  const [activeTimer, setActiveTimer] = useState(false);
  const [taggedDocumentInfo, setTaggedDocumentInfo] = useState(null);
  const [selectAll, setSelectAll] = useState(true);

  useEffect(() => {
    let timer;
    if (activeTimer && taggedDocumentInfo) {
      timer = setInterval(async () => {
        if (projectsService && project && project.uuid) {
          const {
            response: { documents }
          } = await projectsService.loadCurrentProjectDocuments(project.uuid);
          const taggedDocument = documents.filter(
            (doc) => taggedDocumentInfo.includes(doc.uuid) && doc.extractionJob
          );
          const isExtractionInProgress = taggedDocument.some(
            (doc) => doc.extractionJob.status === ExtractionJobStatus.ACTIVE.key
          );
          if (!isExtractionInProgress) {
            clearInterval(timer);
            setActiveTimer(false);
            setTaggedDocumentInfo(null);
          }
        }
      }, 2000); // Making api call in every 2000 ms
    }
    return () => clearInterval(timer);
  }, [activeTimer, projectsService, taggedDocumentInfo, project.uuid]);

  useEffect(() => {
    // if not all documents are selected, select All should be unchecked
    if(selectedDocumentsForModel && selectedDocumentsForModel.length) {
      setSelectAll(true);
      succesfulTaggedDocuments.map(document => {
        const targetRow = selectedDocumentsForModel.find(selectedDoc => selectedDoc.uuid === document.uuid);
        if(!targetRow) {
          setSelectAll(false);
          return;
        }
        document.children.map(child => {
          if(child.extractionJob && child.isValidated) {
            const isFound = targetRow.children.find(rowChild => rowChild.uuid === child.uuid);
            if(!isFound) {
              setSelectAll(false);
              return;
            }
          }
        });
      });
    } else {
      setSelectAll(false);
    }
  }, [selectedDocumentsForModel, succesfulTaggedDocuments])

  const onDocumentTaggingSuccess = useCallback((taggingInfo) => {
    const {
      response: { taggings }
    } = taggingInfo;
    const taggeddDocumentUuids =
      !!taggings.length &&
      taggings.map((document) => document.taggedDocument.uuid);
    setTaggedDocumentInfo(taggeddDocumentUuids || null);
    setActiveTimer(true);
    setShowTaggingForm(false);
  }, []);

  const onDrop = useCallback(
    (acceptedFiles) => {
      documentsService
        .createDocuments(project, acceptedFiles)
        .catch(console.log);
      eventTrackerService.track(DocumentAddedByDrop);
    },
    [project, documentsService]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true
  });

  function tileContainerClicked(e) {
    if (e.target === e.currentTarget) {
      setSelectedDocument(null);
    }
  }

  function onPopupHide() {
    setShowRenameForm(false);
    setShowDeleteConfirm(false);
    setNeedSupport(false);
    setShowClearTaggingConfirm(false);
    setshowExtractionPendingPopup(false);
  }

  const onRename = useCallback(
    (document) => {
      setDocument(document);
      setShowRenameForm(true);
    },
    [setDocument, setShowRenameForm]
  );

  const onNeedSupport = useCallback((document) => {
    let raiseTicketFor = document;
    if (
      document.groupId === null &&
      document.children &&
      document.children.length === 1
    ) {
      raiseTicketFor = document.children[0];
    }
    setNeedSupport(true);
    setDocument(raiseTicketFor);
  }, []);

  const onCancel = useCallback(() => {
    setShowTaggingForm(false);
  });

  const onReExtract = useCallback(
    async (document) => {
      const reExtract = async (document) => {
        if (getIsDocumentReExtractRequired(document)) {
          return documentsService.reExtractDocument(project, document);
        }
      };
      if (document.children) {
        await Promise.all(document.children.map(reExtract));
      } else {
        await reExtract(document);
      }

      await documentsService.projectsService
        .loadCurrentProjectDocuments(project.uuid)
        .catch(console.log);
    },
    [project]
  );

  function onOpen(document) {
    if (
      document.extractionJob &&
      (document.extractionJob.status === ExtractionJobStatus.SUCCESSFUL.key ||
        document.extractionJob.status === ExtractionJobStatus.FAILED.key ||
        document.extractionJob.status === ExtractionJobStatus.CONVERTED.key)
    ) {
      router.setRoute('docExtractionPage', {
        documentUuid: document.uuid,
        projectUuid: project.uuid
      });

      eventTrackerService.track(DocumentOpened, document);
    } else if (
      document.extractionJob &&
      document.extractionJob.status === ExtractionJobStatus.ACTIVE.key
    ) {
      setshowExtractionPendingPopup(true);
    } else {
      router.setRoute('documentPage', {
        documentUuid: document.uuid,
        projectUuid: project.uuid
      });
    }
  }

  function onDownloadCSV(document) {
    documentsService.downloadCSV(project, document);
    eventTrackerService.track(DocumentOpened, document);
  }

  function onDelete(document) {
    setDocument(document);
    setShowDeleteConfirm(true);
  }

  function onClearTagging(document) {
    setDocument(document);
    setShowClearTaggingConfirm(true);
  }

  function onSheetChange(activeSheetName) {
    store.dispatch(setCurrentActiveViewerSheet(activeSheetName));
  }

  async function onDeleteConfirm(document) {
    onPopupHide();
    if (document.children) {
      await Promise.all(
        document.children.map(async (document) => {
          if (document.groupId !== document.uuid) {
            return await documentsService.deleteDocument(project, document);
          }
        })
      );
      await documentsService.deleteDocument(project, document);
    } else {
      document.groupId
        ? await documentsService.clearChildDocumentTagging(project, document)
        : await documentsService.deleteDocument(project, document);
    }
    isReportEnabled && await projectReportService.clearReportData(project);
    setSelectedDocument(null);
    toastrService.success(messages.toastMessage.DELETE_DOCUMENT);
  }

  async function onClearTaggingConfirm(document) {
    onPopupHide();
    if (document.children) {
      await Promise.all(
        document.children.map(async (d) => {
          if (d.uuid !== d.groupId) {
            return await documentsService
              .clearChildDocumentTagging(project, d)
              .catch(console.log);
          } else {
            return await documentsService
              .clearTagging(project, d)
              .catch(console.log);
          }
        })
      );
    } else {
      return await documentsService
        .clearTagging(project, document)
        .catch(console.log);
    }
    
    isReportEnabled && await projectReportService.clearReportData(project);
    toastrService.success(messages.toastMessage.CLEAR_DOCUMENT_TAG);
  }

  const hasTrialExhausted = useMemo(
    () =>
      MAX_ALLOWED_EXTRACTION <= eventCounts.documentsExtracted &&
      !!appConfig.isFreebieApp,
    [eventCounts.documentsExtracted]
  );

  const handleSelectAllButton = React.useCallback((e) => {
      setSelectAll(e.target.checked);
      const docs = Array.from(new Set(documents));
      if(e.target.checked) {
        setSelectedDocumentsForModel(docs);
      } else {
        setSelectedDocumentsForModel([]);
      }
  }, [setSelectAll, setSelectedDocumentsForModel, documents]);

  const View = isListView ? DocumentsListView : DocumentsGridView;


  return (
    <>
      <div className="horizontal-section flex-grow-1 position-relative">
        {showTaggingForm ? (
          <div
            style={{ flex: '1' }}
            className="position-relative list-view table-container-section"
          >
            {hasTrialExhausted ? (
              <ContactSalesForm onCancel={onCancel} />
            ) : (
              <DocumentTaggingForm
                project={project}
                onCancel={onCancel}
                document={selectedDocument}
                onSuccess={onDocumentTaggingSuccess}
                pageCount={pageCount}
                sheetNames={sheetNames}
                onSheetChange={(activeSheetName) =>
                  onSheetChange(activeSheetName)
                }
              />
            )}
          </div>
        ) : (
          <div
            style={{ flex: '3' }}
            className="position-relative"
            {...getRootProps({})}
          >
            <input {...getInputProps()} />
            <div
              style={{ backgroundImage: `url(${uploadTextBackground})` }}
              onClick={tileContainerClicked}
              id="documentsView"
              className={`horizontal-section justify-content-start align-content-start flex-wrap fill-parent overflow-auto ${
                style.documentListingCol
              } ${style.dropableDivImage} ${style.projectsListViewScroll} ${
                style.gridViewSection
              } ${isListView ? 'list-view containerWrapper' : 'grid-view'}`}
            >

              <ModelMessage/>
              <View
                documents={documents}
                onNeedSupport={onNeedSupport}
                onClearTagging={onClearTagging}
                onAddTagging={onAddTagging}
                onReExtract={onReExtract}
                onDelete={onDelete}
                onDownloadCSV={onDownloadCSV}
                onOpen={onOpen}
                onRename={onRename}
                handleSelectAllButton={handleSelectAllButton}
                selectAllModels={selectAll}
                selectDocument={onDocumentSelect}
                selectedDocument={selectedDocument}
                goToAddTaggingStep={goToAddTaggingStep}
                hasTrialExhausted={hasTrialExhausted}
                selectedDocumentsForModel={selectedDocumentsForModel}
                setSelectedDocumentsForModel={setSelectedDocumentsForModel}
                succesfulTaggedDocuments={succesfulTaggedDocuments}
              />
            </div>
          </div>
        )}
        {
          <div
            id="DocumentPreviewWrapper"
            className={`vertical-section flex-shrink-0 bg-light ${style.toolbarViewerSpacerRight} ${style.pdfViewerWrapper}`}
            style={{ flex: '3' }}
          >
            {selectedDocument ? (
              <DocumentViewer
                document={selectedDocument}
                project={project}
                setPdfPageCount={setPageCount}
                setXLSheetNames={setSheetNames}
                small
              />
            ) : (
              <div
                className={`horizontal-section flex-grow-1 position-relative ${style.noDocumentsDivImage}`}
                style={{ backgroundImage: `url(${noDocumentsBackground})` }}
              >
                {' '}
              </div>
            )}
          </div>
        }
      </div>

      <DocumentPopups
        document={document}
        project={project}
        needSupport={needSupport}
        onHide={onPopupHide}
        showDeleteConfirm={showDeleteConfirm}
        showRenameForm={showRenameForm}
        onDeleteConfirm={() => onDeleteConfirm(document)}
        showClearTaggingConfirm={showClearTaggingConfirm}
        onClearTaggingConfirm={() => onClearTaggingConfirm(document)}
        showExtractionPendingPopup={showExtractionPendingPopup}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  eventCounts: eventCountsSelector(state) || {},
  isReportEnabled: currentProjectIsReportEnabledSelector(state)
});

export default connect(mapStateToProps)(
  withServices(
    'documentsService',
    'router',
    'store',
    'toastrService',
    'projectsService',
    'projectReportService',
    'eventTrackerService'
  )(DocumentsView)
);
