/* eslint-disable prefer-destructuring */
import GC from '@grapecity/spread-sheets';
import '@grapecity/spread-sheets-charts';
import "@grapecity/spread-sheets-io";
import {
  isNil, get as getProperty, sortBy, groupBy, orderBy
} from 'lodash';
import moment from 'moment';
import {
  DocumentFileTypes, projectReportsConstantsMonthRows, reportSheetName, USCurrencyFormat,
  projectReportRRWorkingAsOnDate, projectReportsConstantsTrailingColRows, projectReportsConstantsYTDColRows,
  projectReportsConstantsBudgetedColRows, projectReportsConstantsPerformaColRows, USDateFormat, 
  projectReportTaggedDocumentColumnInfo, PROJECT_REPORT_URL_ERROR_MSG, projectReportAssumptionColumnInfo, 
  assumptionKeysDataType, columnDataTypeFormatters
} from '../../../constants';

import {
  currentProjectReportSelector, currentProjectServicingUnitStatusAssumption,
  currentProjectProductionUnitStatusAssumption, projectSpecificAssumptionSelector, 
  currentProjectDocumentsAsListSelector
} from '../../../store/selectors';
import { compareTwoArraysInLowerCase, getKeyValuePair, reportDateFormat, sortPeriodDateByYear } from '../../../utils/utils';
import { showBackdropWithProgress, setLoadingPercent, hideBackdropWithProgress } from '../../../store/loadingBackdropWithProgress';
import messages from '../../../../locales/en-US';


const {
  financialSheetName, constantSheetName
} = reportSheetName;
class ProjectReportWorkbookManager {
  constructor(workbookManager, options = {}) {
    this.workbookManager = workbookManager;
    this.options = options;
    this.setReportData();
  }

  updateOptions(options) {
    this.options = {
      ...this.options,
      ...options
    };
  }

  async setReportData(store, reportData) {
    this.workbookManager.suspend();
    if (store) {
      let url;
      store.dispatch(showBackdropWithProgress(0));      
      if (this.options.isOpen) {
        url = `/shared/reports/${this.options.reportUuid}/reportTemplateFile`
        !reportData ? this.loadReportTemplateFile(url, store) : this.loadReportFromJSON(reportData, store)
      } else {
        url = `/projects/${this.options.projectUuid}/models/${this.options.modelUuid}/reportTemplateFile/${this.options.reportTemplateUuid}`;
        this.loadReportTemplateFile(url, store)
      }
    }
    this.workbookManager.resume();
  }

  loadReportTemplateFile(url, store) {
    this.workbookManager.suspend();
    const self = this;
    const requestTemplate = this.options.isOpen ?
      this.options.apiClient.get(
        url, { responseType: 'blob' },
      ) :
      this.options.apiClient.post(
        url,
        { selectedDocuments: this.options.selectedDocuments },
        { responseType: 'blob' },
      )

    requestTemplate.then((blob) => {
      this.workbookManager.workbook.import(blob, function (args) {
        store && store.dispatch(hideBackdropWithProgress());
        // success callback to do something
      }, function ({ errorCode, errorMessage }) {
        store && store.dispatch(hideBackdropWithProgress());
        const message = errorCode === 1 ? messages.projectReport.invalidTemplates : errorMessage;
        this.options.toastrService.error(message, 'top')
      }, {
        fileType: GC.Spread.Sheets.FileType.excel,
        OpenMode: 2,
        fullRecalc: true,
        progress: ({ progress }) => {
          if (progress) {
            this.options.setReportLoadingPercentage((progress * 100).toFixed(1))
            if (progress === 1) {
              this.options.setIsReportLoaded(true);
             }
          }
        }
      });
    }).catch(error => {
      this.options.toastrService.error(PROJECT_REPORT_URL_ERROR_MSG, 'top')
    });
    this.workbookManager.resume();
  }
  validateFormulaOnSheets() {
    this.workbookManager.suspend();
    const sheets = this.workbookManager.getSheets();
    const sheetsToIgnore = ['RR All'];
    const allSheets = this.workbookManager.getSheetNames();
    const sheetsWithFormula = compareTwoArraysInLowerCase(allSheets, Object.values(reportSheetName));    

    for (let sheet of sheets) {
      if (sheetsWithFormula.includes(sheet.name()) && !sheetsToIgnore.includes(sheet.name())) {
        sheet.recalcAll();
      }    
    }
    console.log('loaded');
    this.workbookManager.resume();
  }
  
  loadReportFromJSON(reportData, store) {
    this.workbookManager.suspend();
    store.dispatch(showBackdropWithProgress(0));
    const jsonOptions = {
      incrementalLoading: {
        loading: function (progress, args) {
          if (store && progress) {
            store && store.dispatch(setLoadingPercent((progress * 100).toFixed(1)));
          }
        },
        loaded: function () {
          store && store.dispatch(hideBackdropWithProgress());
        }
      },
    }
    this.workbookManager.workbook.fromJSON(reportData, jsonOptions);
    this.workbookManager.resume();
  }

  setSheetData(reports) {
    this.workbookManager.suspend();
    Reflect.ownKeys(reports).forEach((sheetName) => {
      if (reports[sheetName]) {
        this.setSheet(sheetName, reports[sheetName]);
      }
    });
    this.addColRowToConstantSheet()
    this.setDocumentTaggingInfo();
    this.setProjectAssumption();
    this.setDefaultConfig();
    this.bindSheetEvents();
    this.workbookManager.resume();
  }

  getSheetByName(sheetName) {
    const { sheets } = this.workbookManager.workbook;
    let result = null;
    if (sheets.length) {
      result = sheets.map((sheet, sheetIndex) => 
        sheet.name() === sheetName && { sheet, sheetIndex }).filter(sheet => sheet)[0];
    }
    return result;
  }


  setHeaderStyle(sheetIndex, sheetName, row, columns) {
    this.workbookManager.suspend();
    const columnCount = sheetName === financialSheetName ? columns.length - 4 : columns.length;
    this.workbookManager.setCellRangeAttr(sheetIndex, row, 0, 1, columnCount, 'backColor', '#213a64');
    this.workbookManager.setCellRangeAttr(sheetIndex, row, 0, 1, columns.length, 'foreColor', 'white');

    if (sheetName === financialSheetName) {
      this.workbookManager.setCellRangeAttr(sheetIndex, row, columns.length - 4, 1, 4, 'backColor', '#66b6f6'); // setting diffrent color to trailing months.
    }

    this.workbookManager.resume();
  }

  setColumnFormat(sheetIndex, columns, rowCount, initialRow) {
    this.workbookManager.suspend();
    const sheet = this.workbookManager.getSheet(sheetIndex);

    columns && columns.forEach((column, columnIndex) => {
      if (column && column.isAmountValue) {
        this.workbookManager.setCellRangeAttr(sheetIndex, 1, columnIndex, rowCount, 1, 'formatter', USCurrencyFormat);
        sheet.autoFitColumn(columnIndex);
      }
      if (column && column.isDateField) {
        this.setDateFormatToCell(sheet, { initialRow: 1, col: columnIndex, rowCount, colCount: 1 })
        sheet.autoFitColumn(columnIndex);
      }
      // eslint-disable-next-line no-prototype-builtins
      if (column && (column?.hasOwnProperty('isSubCategoryAvailable') && !column.isSubCategoryAvailable)) {
        sheet.setColumnWidth(columnIndex, 0);
        sheet.setColumnResizable(columnIndex, false, GC.Spread.Sheets.SheetArea.colHeader);
      }
      sheet.getRange(initialRow, columnIndex, rowCount + 1, 1).borderRight(new GC.Spread.Sheets.LineBorder("#00000", GC.Spread.Sheets.LineStyle.thin));
    });
    this.workbookManager.resume();
  }

  setDateFormatToCell(sheet, cellRange) {
    this.workbookManager.suspend();
    const { initialRow, col, rowCount } = cellRange;
    for (let row = initialRow; row < rowCount; row++) {
      const columnValue = sheet.getCell(row, col).value();
      this.validateAndSetDateToCell(sheet, row, col, columnValue)
    }
    this.workbookManager.resume();
  }

  validateAndSetDateToCell(sheet, row, col, date) {
    this.workbookManager.suspend();
    const isValidDate = moment(new Date(date), "MM/DD/YYYY")._isValid;
    if (!!date && isValidDate) {
      sheet.setValue(row, col, new Date(date));
      sheet.setFormatter(row, col, USDateFormat);
    }
    this.workbookManager.resume();
  }

  setColumnOutlines(sheet, columns) {
    this.workbookManager.suspend();
    const monthsColumnIndexes = [];
    columns.forEach((col, index) => {
      if (col && col.isOutlineEnable) {
        monthsColumnIndexes.push(index);
      }
    });
    if (monthsColumnIndexes.length > 1) {
      const startIndex = monthsColumnIndexes[0];
      const endIndex = monthsColumnIndexes[monthsColumnIndexes.length - 1] - monthsColumnIndexes[0] + 1;
      sheet.columnOutlines.group(startIndex, endIndex);
      const sheetOutlines = this.getColumnOutlineLevels(sheet, monthsColumnIndexes.length + 1);
      if (sheetOutlines.length) {
        sheet.columnOutlines.expandGroup(sheetOutlines[0], false);
        sheet.invalidateLayout();
      }
    }
    this.workbookManager.resume();
  }

  getColumnOutlineLevels(sheet, columnCount) {
    this.workbookManager.suspend();
    const outlineLevels = sheet.columnOutlines.getMaxLevel();
    const outlines = [];
    for (let index = 0, i = 0; index <= outlineLevels; index++) {
      for (let col = 0; col < columnCount; col++) {
        // Fetch group information of Outline
        const groupInfo = sheet.columnOutlines.find(col, index);
        if (!isNil(groupInfo)) {
          outlines[i] = groupInfo;
          i++;
          col = groupInfo.end;
        }
      }
    }
    this.workbookManager.resume();
    return outlines;
  }

  getMonthlyDataStatus(isMonthlyDataAvailable) {
    return isMonthlyDataAvailable ? 'Monthly' : 'Annually';
  }

  setConstantsMonthsInfo(sheetIndex, periodInfo) {
    this.workbookManager.suspend();
    const sheet = this.workbookManager.getSheet(sheetIndex);
    if (periodInfo && !!Reflect.ownKeys(periodInfo).length) {
      const { taggedPeriods } = periodInfo;
      const taggedYears = Reflect.ownKeys(taggedPeriods);
      const {
        yearCol, monthCol, periodFromCol, periodToCol, monthStatusCol, yearStartRow, yearEndRow
      } = projectReportsConstantsMonthRows;
      taggedYears.forEach((year, yearRowIndex) => {
        const yearRow = yearRowIndex + yearStartRow;
        const {
          monthsExtracted, monthlyDataAvailable, periodFrom, periodTo
        } = taggedPeriods[year];
        const monthColumnCaption = `${year} Amount`;
        if (yearRow < yearEndRow) {
          const months = Array.from(new Set(monthsExtracted));
          this.workbookManager.setCellValue(sheetIndex, yearRow, monthCol, months.length);
          this.workbookManager.setCellValue(sheetIndex, yearRow, yearCol, monthColumnCaption);
          this.setDateWithFormatter(
            sheet,
            yearRow,
            [periodFromCol, periodToCol],
            [periodFrom, periodTo]
          )
          this.workbookManager.setCellValue(
            sheetIndex, 
            yearRow, 
            monthStatusCol, 
            this.getMonthlyDataStatus(monthlyDataAvailable)
          );
        }
      });
    }
    this.workbookManager.resume();
  }

  setConstantsBudgetedMonths(sheetIndex, periodMetaInfo) {
    this.workbookManager.suspend();
    const sheet = this.workbookManager.getSheet(sheetIndex);
    const documents = periodMetaInfo.filter(meta => meta.documentType === DocumentFileTypes.OS_BUDGETED.key);
    const budgetedYearMonthInfo = sortPeriodDateByYear(documents);
    const periodMonths = Object.values(budgetedYearMonthInfo);
    const periodYear = Reflect.ownKeys(budgetedYearMonthInfo);

    projectReportsConstantsBudgetedColRows.forEach((period, index) => {
      const foundPeriod = documents.find(date => moment(date.periodFrom, 'MM/DD/YYYY').format('YYYY') === periodYear[index]) || null;
      this.workbookManager.setCellValue(sheetIndex, period.yearRow, period.monthCol, periodMonths[index] || null);
      if (!isNil(foundPeriod) && !!periodMonths[index]) {
        this.setDateWithFormatter(
          sheet,
          period.yearRow,
          [period.periodFromCol, period.periodToCol],
          [foundPeriod.periodFrom, foundPeriod.periodTo]
        )
      }
    });
    this.workbookManager.resume();
  }

  setConstantsPerformaMonths(sheetIndex, periodMetaInfo) {
    this.workbookManager.suspend();
    const sheet = this.workbookManager.getSheet(sheetIndex);
    const documents = periodMetaInfo.filter(meta => meta.documentType === DocumentFileTypes.OS_PROFORMA.key);
    const performaYearMonthInfo = sortPeriodDateByYear(documents);
    const periodMonths = Object.values(performaYearMonthInfo);
    const periodYear = Reflect.ownKeys(performaYearMonthInfo);

    projectReportsConstantsPerformaColRows.forEach((period, index) => {
      const foundPeriod = documents.find(date => moment(date.periodFrom, 'MM/DD/YYYY').format('YYYY') === periodYear[index]) || null;
      const documentTaggedMonth = periodMonths[index] ? periodMonths[index] : null;
      this.workbookManager.setCellValue(sheetIndex, period.yearRow, period.monthCol, documentTaggedMonth);
      if (!isNil(foundPeriod) && !!periodMonths[index]) {
        this.setDateWithFormatter(
          sheet,
          period.yearRow,
          [period.periodFromCol, period.periodToCol],
          [foundPeriod.periodFrom, foundPeriod.periodTo]
        )
      }
    });
    this.workbookManager.resume();
  }

  setConstantsTrailingMonthsInfo(sheetIndex, sheetData) {
    this.workbookManager.suspend();
    const sheet = this.workbookManager.getSheet(sheetIndex);
    const trailingColumnName = sheetData.trailingMonthName || 'N/A';
    if (sheetData.periodInfo) {
      const { periodInfo: { t12, ytd } } = sheetData;
      const {
        monthsExtracted, periodFrom, periodTo, monthlyDataAvailable
      } = t12;
      const months = Array.from(new Set(monthsExtracted));
      projectReportsConstantsTrailingColRows.forEach((period) => {
        this.workbookManager.setCellValue(sheetIndex, period.yearRow, period.monthCol, months.length);
        this.workbookManager.setCellValue(sheetIndex, period.yearRow, period.yearCol, trailingColumnName);
        this.setDateWithFormatter(
          sheet,
          period.yearRow,
          [period.periodFromCol, period.periodToCol],
          [periodFrom, periodTo]
        )
        this.workbookManager.setCellValue(
          sheetIndex, 
          period.yearRow, 
          period.monthStatusCol, 
          this.getMonthlyDataStatus(monthlyDataAvailable)
        );
      });
      this.setYTDTrailingMonthsInfo(sheet, sheetIndex, ytd);
    }
    this.workbookManager.resume();
  }

  setYTDTrailingMonthsInfo(sheet, sheetIndex, ytdInfo) {
    this.workbookManager.suspend();
    if (ytdInfo) {
      const {
        monthsExtracted, periodFrom, periodTo, monthlyDataAvailable
      } = ytdInfo;
      const months = Array.from(new Set(monthsExtracted));
      const YtdCaption = months.length ? 'YTD' : 'N/A';
      projectReportsConstantsYTDColRows.forEach((period) => {
        this.workbookManager.setCellValue(sheetIndex, period.yearRow, period.monthCol, months.length);
        this.workbookManager.setCellValue(sheetIndex, period.yearRow, period.yearCol, YtdCaption);
        this.setDateWithFormatter(
          sheet,
          period.yearRow,
          [period.periodFromCol, period.periodToCol],
          [periodFrom, periodTo]
        )
        this.workbookManager.setCellValue(
          sheetIndex, 
          period.yearRow, 
          period.monthStatusCol, 
          this.getMonthlyDataStatus(monthlyDataAvailable)
        );
      });
    }
    this.workbookManager.resume();
  }

  setDefaultConfig() {
    this.workbookManager.suspend();
    this.workbookManager.workbook.options.newTabVisible = false;
    this.workbookManager.workbook.options.incrementalLoading = true;
    this.workbookManager.workbook.setActiveSheet(financialSheetName);
    this.workbookManager.resume();
  }

  bindSheetEvents() {
    const { sheetIndex } = this.getSheetByName(constantSheetName);
    this.workbookManager.workbook.bind(GC.Spread.Sheets.Events.ActiveSheetChanged, (sheetName, { newSheet }) => {
      this.workbookManager.workbook.refresh();
      newSheet.setColumnVisible(0, true);
    });
    // Hide Constant Sheet here
    this.workbookManager.workbook.sheets[sheetIndex].visible(false);
  }

  updateConstantsUnitNumbers() {
    const state = this.options.store.getState();
    const currentReport = currentProjectReportSelector(state);
    const currentProjectRRRowsCount = getProperty(currentReport, "['RR Working'].rows.length", 0);
    const unitNumbersProductionAssumption = currentProjectProductionUnitStatusAssumption(state);
    const unitNumbersServicingAssumption = currentProjectServicingUnitStatusAssumption(state);
    let unitStatus = 1;

    if (!unitNumbersProductionAssumption || unitNumbersProductionAssumption === 1234) {
      if (!unitNumbersServicingAssumption || unitNumbersServicingAssumption === 'Annual') {
        unitStatus = currentProjectRRRowsCount || 1;
      } else {
        unitStatus = unitNumbersServicingAssumption;
      }
    } else {
      // eslint-disable-next-line no-unused-vars
      unitStatus = unitNumbersProductionAssumption;
    }

    // this.workbookManager.getSheet(constantSheetIndex).getCell(
    //   CF_UNIT_STATUS_COL_INFO.row, CF_UNIT_STATUS_COL_INFO.column
    // ).value(unitStatus);
  }

  setAsOnDateToRRWorking(sheetIndex, asOnDate = "") {
    this.workbookManager.suspend();
    const sheet = this.workbookManager.getSheet(sheetIndex);
    const { row, column } = projectReportRRWorkingAsOnDate;
    this.validateAndSetDateToCell(sheet, row, column, asOnDate);
    this.workbookManager.resume();
  }

  addColRowToConstantSheet() {
    this.workbookManager.suspend();
    const { sheetIndex } = this.getSheetByName(constantSheetName);
    this.workbookManager.addAdditionalRowsColumns(sheetIndex, 50, 100);
    this.workbookManager.resume();
  }

  setDocumentTaggingInfo() {
    this.workbookManager.suspend();
    const { sheetIndex } = this.getSheetByName(constantSheetName);
    const documentList = currentProjectDocumentsAsListSelector(this.options.store.getState());
    const sortByDocumentType = sortBy(documentList.filter(doc => !!Reflect.ownKeys(doc.taggingData).length).map(doc => doc.taggingData), 'documentType') || [];
    const groupedByDocumentType = groupBy(sortByDocumentType, 'documentType');
    const sheet = this.workbookManager.getSheet(sheetIndex)
    const taggedDocumentInfo = Reflect.ownKeys(groupedByDocumentType).map(docType => {
      const keyToGroupBy = docType !== 'RENT_ROLL' ? 'periodFrom' : 'asOnDate';
      // eslint-disable-next-line new-cap
      return orderBy(groupedByDocumentType[docType], (taggingInfo) => new moment(taggingInfo[keyToGroupBy]), ['asc']);
    }).flat();

    if (taggedDocumentInfo.length) {
      const { 
        rowIndex, docNameColIndex, docTypeColIndex, docAsOfDateColIndex, docPeriodFromColIndex, docPeriodToColIndex 
      } = projectReportTaggedDocumentColumnInfo;
      taggedDocumentInfo.forEach((taggingInfo, index) => {
        const row = rowIndex + index;
        this.workbookManager.setCellValue(sheetIndex, row, docNameColIndex, `document${index + 1}`);
        this.workbookManager.setCellValue(sheetIndex, row, docTypeColIndex, taggingInfo.documentType);
        this.setDateWithFormatter(
          sheet,
          row,
          [docAsOfDateColIndex, docPeriodFromColIndex, docPeriodToColIndex],
          [taggingInfo.asOnDate, taggingInfo.periodFrom, taggingInfo.periodTo]
        )
      });
      this.workbookManager.getSheet(sheetIndex).getRange(rowIndex, docNameColIndex, taggedDocumentInfo.length, 5)
        .setBorder(new GC.Spread.Sheets.LineBorder("#000", GC.Spread.Sheets.LineStyle.thin), { all: true }, 3);
    }
    this.workbookManager.resume();
  }

  setDateWithFormatter(sheet, row, columns = [], dates = []) {
    this.workbookManager.suspend();
    columns.forEach((col, colIndex) => {
      if (dates[colIndex]) {
        sheet.setValue(row, col, reportDateFormat(dates[colIndex]));
        sheet.setFormatter(row, col, USDateFormat);
      }
    });
    this.workbookManager.resume();
  }

  setProjectAssumption() {
    this.workbookManager.suspend();
    const { sheetIndex, sheet } = this.getSheetByName(constantSheetName);
    const projectAssumption = projectSpecificAssumptionSelector(this.options.store.getState());
    if (projectAssumption) {
      const assumptions = getKeyValuePair(projectAssumption);
      const { rowIndex, propertyKeyName, propertyValueName } = projectReportAssumptionColumnInfo;
      Reflect.ownKeys(assumptions).forEach((key, index) => {
        const row = rowIndex + index;
        this.workbookManager.setCellValue(sheetIndex, row, propertyKeyName, key);
        this.workbookManager.setCellValue(sheetIndex, row, propertyValueName, assumptions[key]);
        if (Reflect.ownKeys(assumptionKeysDataType).includes(key)) {
          const dataType = assumptionKeysDataType[key];
          sheet.setFormatter(row, propertyValueName, columnDataTypeFormatters[dataType]);
        }
      });
      this.workbookManager.getSheet(sheetIndex).getRange(
        rowIndex, 
        propertyKeyName, 
        Reflect.ownKeys(assumptions).length, 2
      )
        .setBorder(new GC.Spread.Sheets.LineBorder("#000", GC.Spread.Sheets.LineStyle.thin), { all: true }, 3);
    }
    // auto adjusting the column width of constant sheet.
    this.workbookManager.autoFitColumns(sheetIndex);
    this.workbookManager.resume();
  }


}
export default ProjectReportWorkbookManager;
