import React, { useState, useCallback } from 'react';
import { withServices } from 'reaf';
import {
    Row, Col, ListGroup, Badge, Button
} from 'react-bootstrap';
import AssetTypeBadge from '../AssetTypeBadge/AssetTypeBadge';
import SwitchButton from "../../core/SwitchButton/SwitchButton";
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import { faFile } from '@fortawesome/free-solid-svg-icons/faFile';
import { faInfo as infoIcon } from '@fortawesome/free-solid-svg-icons/faInfo';
import IconButton from '../../core/Button/IconButton';
import WithTooltip from '../../core/WithTooltip/WithTooltip';
import ReportTemplatesModal from '../ReportTemplatesModal/ReportTemplatesModal';

function TemplateModelListItem({
  model, template, templateModelService, onDownloadModel,
  onDeleteTemplateModel, onEditModel, onModelInformation, templateUUID
}) {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);

  function ToggleButton({ isActive, title }) {
    const [isActiveToggle, setIsActiveToggle] = React.useState(isActive);

    const onToggle = React.useCallback(async () => {
        setIsActiveToggle(!isActiveToggle);
        await templateModelService.updateGlobalModelConsolidatedModelAccess(template, model, !isActiveToggle);
    }, [isActiveToggle, templateModelService, template, model]);

    return (
        <div className="d-flex justify-content-around align-items-center">
            <label>{title}</label>
            <div>
                <SwitchButton
                    isActive={isActiveToggle}
                    onChange={onToggle}
                />
            </div>
        </div>
    );
  }

  const onDeleteReportTemplate = useCallback( async (model, reportTemplate) => {
    try {
      await templateModelService.deleteReportTemplate(null, templateUUID, model, reportTemplate);
    } catch (error) {
      if (error.status === 400) {
        // Let this alert be here... It's intentional to show the error to the admin.
        // eslint-disable-next-line no-alert
        alert(error.message);
      }
    }
  }, [templateUUID])

  const onDownloadReportTemplate = useCallback(async (model, reportTemplate) => {
    try {
      await templateModelService.downloadReportTemplate(null, templateUUID, model, reportTemplate);
    } catch (error) {
      if (error.status === 400) {
        // Let this alert be here... It's intentional to show the error to the admin.
        // eslint-disable-next-line no-alert
        alert(error.message);
      }
    }
  }, [templateUUID]);

  return (
    <ListGroup.Item>
      <Row className="align-items-center">
        <Col>
          <WithTooltip tooltipText={model?.modelFilePath}>
            <div>
              {model.modelName}
              {model.assetType && <AssetTypeBadge key={model.assetType} AssetType={model.assetType} />}
            </div>
          </WithTooltip>
          <Badge pill variant="info"></Badge>
        </Col>
        <Col>
          <ToggleButton
            isActive={model.allowConsolidatedModel}
            title="Allow Download consolidated model"
          />
        </Col>
        <Col xs lg="4" className="d-flex align-items-center">
          { !!model.modelFilePath && <IconButton
            className="btn btn-default btn-sm mr-2"
            variant="outline-primary"
            onClick={() => onDownloadModel(model)}
            title="Download Model"
            text="Model Template"
            icon={faDownload}
          />}
          { !!model.hasReportTemplateFile && <IconButton
            className="btn btn-default btn-md"
            variant="outline-primary"
            onClick={toggleModal}
            title="Manage Reports Templates"
            text="Manage Reports"
            icon={faFile}
          />}
        </Col>
        <Col xs lg="3">
          <>
            <IconButton
              className="btn btn-default btn-sm mb-1"
              title="Delete Report Template"
              variant="outline-danger"
              onClick={() => onDeleteTemplateModel(model)}
              icon={faTrash}
              text="Delete"
            />
            <IconButton
              className="btn btn-default btn-sm mb-1"
              title="Update Report Template"
              variant="outline-primary"
              onClick={() => onEditModel(model)}
              icon={faPencilAlt}
              text="Update"
            />
            <IconButton
              className="btn btn-default btn-sm"
              title="Model Information"
              variant="outline-primary"
              onClick={() => onModelInformation(model)}
              icon={infoIcon}
            />
          </>
        </Col>
      </Row>
      <ReportTemplatesModal
        show={showModal}
        onHide={toggleModal}
        model={model}
        onDownloadReportTemplate={onDownloadReportTemplate}
        onDeleteReportTemplate={onDeleteReportTemplate}
      />
    </ListGroup.Item>
  );
}

export default withServices('templateModelService')(TemplateModelListItem);
