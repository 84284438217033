import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen as folderOpenIcon } from '@fortawesome/free-solid-svg-icons/faFolderOpen';
import style from './Projects.module.scss'

function ProjectZeroState({ onAdd, isReadOnly }) {
  return (
    <>
      <div style={{ flexGrow: 1 }} className="w-100 h-100">
        <div className="h-100 d-flex justify-content-center ">
          <div className="vertical-section h-100 d-flex justify-content-center bg-light dotted-bg">
             <div className={`text-center ${style.emptyProject}`}>
              <div>
                <p className={`text-secondary mb-3 ${style.title}`}>
                  No properties yet
                </p>
              </div>
              <div className={`${style.documentFileIcon} mb-3`}>
                <FontAwesomeIcon icon={folderOpenIcon} className={style.icon} />
              </div>
              <span className="text-muted">
                Looks like you haven&apos;t added any property yet. <br/>Click on the
                Add Property button to create your first property!
              </span>
              <p className="mt-3">
                <Button
                  variant="primary"
                  className="mt-3 rounded-pill btn-lg"
                  size="lg"
                  onClick={onAdd}
                  type="button"
                >
                  Add Property
                </Button>
              </p>
            </div> 
            {/* <div className="d-flex justify-content-center">
             <p className="mt-3">
                <Button
                  variant="primary"
                  className="mt-3 rounded-pill btn-lg"
                  size="lg"
                  onClick={onAdd}
                  disabled={isReadOnly}
                  type="button"
                >
                  Create your first property
                </Button>
              </p>
            </div>
            <div className="d-flex flex-column align-content-center justify-content-center w-100 h-100">
              <h6 className="justify-content-center d-flex">Or Schedule a demo</h6>
              <iframe 
                title="Clikai-onboarding" 
                className="w-100 h-100" 
                frameBorder="0" 
                src="https://meetings.hubspot.com/rajeev-kohli/clikai-onboarding?embed=true" 
              />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default ProjectZeroState;
